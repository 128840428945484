<template>
  <div class="container">
    <ul class="card_itg_rule-info">
      <li class="flex p-t-b-8">
        <div class="rule-title">当前积分规则：</div>
        <div class="rule-info flex align-center">
          <div>
            每消费1元，获得<span class="p-l-r-10">{{
              integralConfigData.number
            }}</span
            >积分
          </div>
        </div>
      </li>
      <li class="flex p-t-b-8">
        <div class="rule-title">设置积分规则：</div>
        <div class="rule-info">
          <div class="flex align-center">
            每消费1元，获得
            <el-input
              size="small"
              style="width: 150px; margin: 0 10px"
              v-model="number"
              placeholder=""
            ></el-input>
            积分
            <el-button
              :style="{ height: '32px', margin: '0px 10px' }"
              size="small"
              type="primary"
              :loading="loading"
              @click="setIntegralConfigData"
              >设置</el-button
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from "@/api";
export default {
  //引入组件
  components: {},
  data() {
    return {
      number: "",
      loading: false,
      integralConfigData: {
        number: 0,
      },
    };
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //计算机属性
  ///////////////////////////////////////////////////////////////////////////////////////////
  computed: {},
  ///////////////////////////////////////////////////////////////////////////////////////////
  //监听页面加载，其参数为上个页面传递的数据，参数类型为Object（用于页面传参）
  ///////////////////////////////////////////////////////////////////////////////////////////
  created() {
    this.getIntegralConfig();
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //方法调用
  ///////////////////////////////////////////////////////////////////////////////////////////
  methods: {
    // 获取设置详情
    async getIntegralConfig() {
      let result = await api.common.get({
        apiName: "/vip/integralConfig/detail",
      });
      this.integralConfigData = result.data;
      console.log(result);
    },
    // 更新积分设置
    async setIntegralConfigData() {
      if (this.$test.isEmpty(this.number)) return;
      this.loading = true;
      let result = await api.common.post({
        apiName: "/vip/integralConfig/update",
        id: this.integralConfigData.id,
        number: this.number,
      });
      // 请求结果 ->捕获异常
      if (result.code == 200) {
        this.$message({ message: result.message, type: "success" });
        this.getIntegralConfig();
      } else {
        this.$message({ message: result.message, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.rule-title {
  width: 130px;
  color: #434343;
  font-size: 13px;
  background: #eee;
  border-radius: 19px;
  text-align: left;
  padding: 12px 0;
  margin-right: 160px;
  text-align: center;
}
.rule-info {
  font-size: 13px;
  width: 500px;
}
</style>